<!-- 招生系统基础配置 -- 中考考试科目管理 -- 列表 -->
<template>
	<div>
		<list-template :current-page="page" :loading="loading" :search-config="searchConfig" :table-config="tableConfig" :table-data="tableData" :total="total" has-add @onAdd="$router.push('./add')"
										@onChangePage="handleCurrentChange" @onHandle="handleEdit" @onReset='reset' @onSearch="onSearch"></list-template>

		<!-- 历史记录 -->
		<h-dialog :historyDialogShow="historyDialogShow" :historyList="historyList" @close="historyDialogShow = false"></h-dialog>
	</div>
</template>

<script>
//引入ajax接口管理层的接口函数
import {mapState} from "vuex";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  components: {ListTemplate},
	data() {
		return {
			loading: true,
			search: {province: '', city: '', area: ''},
			searchConfig: [
				{
					prop: "school_id",
					tag: 'select',
					placeholder: "筛选校区",
					label: 'school_name',
					value: 'id',
					options: [],
				},
				{
					prop: "rank_name",
					tag: 'select',
					placeholder: "筛选档位",
					options: [],
				},
			],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,

			tableConfig: [
				{prop: "school_name", label: "校区"},
				{prop: "rank_name", label: "档位名称"},
				{prop: "money", label: "金额"},
				{prop: "creator", label: "创建人"},
				{
					prop: "handle",
					label: "操作",
					width: "150rem",
					handle: true,
					render() {
						return ["查看", "编辑", '删除']
					}
				},
			],

			historyDialogShow: false,
			historyList: []
		}
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData()
		this.$_register('api/recruit/common/consulting-school').then(res => {
			this.searchConfig[0].options = res.data.data.entity_school
		})
		this.$_register.get('api/recruit/examination-rank/get-rank').then(res => {
			res.data.data.forEach(item => {
				this.searchConfig[1].options.push({
					label: item,
					value: item
				})
			})
		})
	},
	activated() {
		this.getData()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		getData() {
			let params = {...this.search, page: this.page};
			this.loading = true;
			this.$_register('api/recruit/rank-tuition/list', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => this.loading = false);
		},

		onSearch(val) {
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData(val)
		},


		handleEdit(row, text) {
      if (text.type === 'delete') {
				this.$confirm('是否删除', '提示', {type: 'warning'}).then(() => {
					this.$_register.post('api/recruit/rank-tuition/del', {id: row.id}).then(() => {
						this.$store.commit('setPage', 1);
						this.$message.success('删除成功')
						this.getData()
					})
				});

			}
      if (text.type === 'edit') {
				this.$router.push('./edit?id=' + row.id)
			}
      if (text.type === 'view') {
				this.$router.push('./look?id=' + row.id)
			}
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			//当前页赋值给currentPage
			this.$store.commit("setPage", val);
			//拉取数据
			this.getData()
		},

		reset() {
		}

	},
}
</script>

<style lang="scss" scoped>
.role-list {
	padding-top: 24rem;
}

.button {
	//  padding-top: 30rem;
	box-sizing: border-box;
	width: 90rem;
	height: 34rem;
	margin-bottom: 30rem;
	margin-left: 34rem;
	opacity: 1;
	border-radius: 4rem;
}

.table {
	margin-left: 32rem;
}



::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	font-family: Microsoft YaHei, serif;
	font-size: 14rem;
	font-weight: normal;
	box-sizing: border-box;
	height: 48rem;
	text-align: left;
	color: #333333;
}

.header {
	font-family: Microsoft YaHei, serif;
	font-size: 16rem;
	font-weight: bold;
	line-height: 24rem;
	position: fixed;
	top: 120rem;
	left: 300rem;
	letter-spacing: 3.5rem;
	color: #333333;
}

.el-button--text {
	font-family: Microsoft YaHei, serif;
	font-size: 14rem;
	font-weight: normal;
	margin-right: 40rem;
	color: #3491fa;
}
</style>
